import React, { useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import AppRoutes from './global/AppRoutes';
import { Layout } from './components/Layout';
import { NoJsWarning } from './layout/no-js-error/NoJsWarning';
import './custom.css';
import { Provider } from 'react-redux';
import store from './global/redux-reducer/Store';
import { useDispatch } from 'react-redux';
import { setMediaType } from './global/redux-reducer/MediaQueryReducer';
import { desktopMediaQuery, mobileMediaQuery, tabletMediaQuery } from './global/redux-reducer/MediaQueryReducer'
import { useSelector } from 'react-redux';


export const App = () => {
    const dispatch = useDispatch();
    const mediaClass = useSelector(state => state.mediaQueryReducer.mediaClass);


    useEffect(() => {
        const handleResize = () => {
            if (window.screen.width < 768) {
                dispatch(setMediaType(mobileMediaQuery));
            } else if (window.screen.width >= 768 && window.screen.width < 1024) {
                dispatch(setMediaType(tabletMediaQuery));
            } else {
                dispatch(setMediaType(desktopMediaQuery));
            }
        };

        window.addEventListener('resize', handleResize);

        // Call at initial render
        handleResize();

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [dispatch]
    )

    return (
        <Provider store={store}>
            <div className={mediaClass}>
                <NoJsWarning />
                <Layout>
                    <Routes>
                        {AppRoutes.map((route, index) => {
                            const { element, ...rest } = route;
                            return <Route key={index} {...rest} element={element} />;
                        })}
                    </Routes>
                </Layout>
            </div>
        </Provider>
    );
}
