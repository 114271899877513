export const desktopMediaQuery = 'desktop';
export const mobileMediaQuery = 'mobile';
export const tabletMediaQuery = 'tablet';

const SET_MEDIA_TYPE = 'SET_MEDIA_TYPE';

export const setMediaType = (mediaType) => ({
    type: SET_MEDIA_TYPE,
    payload: mediaType,
});

const initialState = {
    mediaType: desktopMediaQuery,
    mediaClass: desktopMediaQuery
};

const getMediaClass = (mediaType) => {
    if (mediaType === mobileMediaQuery) {
        return 'mobile-media';
    } else if (mediaType === tabletMediaQuery) {
        return 'tablet-media';
    } else if (mediaType === desktopMediaQuery) {
        return 'desktop-media';
    }
}

const mediaQueryReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_MEDIA_TYPE:
            return {
                ...state,
                mediaType: action.payload,
                mediaClass: getMediaClass(action.payload)
            };
        default:
            return state;
    }
};

export default mediaQueryReducer;