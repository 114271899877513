import React from "react";
import footer_youtube from "../../global/images/footer-youtube.gif";
import footer_facebook from "../../global/images/footer-facebook.gif";
import footer_instagram from "../../global/images/footer-instagram.gif";
import footer_logo from "../../global/images/footer-logo.gif";
import footer_logo_writing from "../../global/images/footer-logo-writing.gif";
import { Link } from "react-router-dom";
import { AsthaanURLs, ROUTE_FAITH_URL, ROUTE_ORIGINS_URL } from "../../global/Routes";

export const Footer = () => {

    return (
        <div>
            <div className="topoffooter-divider-container">
                <div className="topoffooter-divider-borders">
                </div>
                <div className="topoffooter-diamond-divider">
                    <hr />
                    <span id="copyright-span">
                        &nbsp; &copy; GurdwaraAPDD 2020
                    </span>
                </div>
                <div className="topoffooter-divider-borders">
                </div>
            </div>

            <footer>
                <div className="footer-border">
                </div>
                <section id="footer-container">
                    <span id="footer-logo-holder">
                        <img id="footer-logo" src={footer_logo} alt="Gurdwara Amrit Parchar Dharmik Diwan UK Logo" />
                        <br className="mo-break" />
                        <img id="footer-logo-writing" src={footer_logo_writing}
                            alt="Gurdwara Amrit Parchar Dharmik Diwan UK" />
                    </span>
                    <div id="footer-explore-links">
                        <h3>Explore</h3>
                        <ul>
                            <li><Link className="footer-navbar" to="/home" >Home</Link></li>
                            <li><Link className="footer-navbar" to={ROUTE_FAITH_URL} >Faith</Link></li>
                            <li><Link className="footer-navbar" to={ROUTE_ORIGINS_URL} >Founders</Link></li>
                            <li><Link className="footer-navbar" to={AsthaanURLs.bradfordURL} >Asthaan</Link></li>
                            {/* <li><a href="#" class="footer-navbar">News</a></li> */}
                            {/* <li><a href="#" class="footer-navbar">Contact</a></li> */}
                        </ul>
                    </div>
                    <div id="contact-us-holder">
                        <h3>Contact us</h3>
                        <ul>
                            <li><Link className="footer-navbar" to={AsthaanURLs.bradfordURL} >bradford@gurdwaraapdd.com</Link></li>
                            <li><Link className="footer-navbar" to={AsthaanURLs.oldburyURL} >oldbury@gurdwaraapdd.com</Link></li>
                            <li><Link className="footer-navbar" to={AsthaanURLs.highWycombeURL} >highwycombe@gurdwaraapdd.com</Link></li>
                        </ul>
                    </div>
                    <div id="footer-follow-us-subscribe">
                        <h3>Follow us</h3>
                        <Link to="https://www.youtube.com/channel/UCI1rpTU_zYrwaChpaEauX3Q">
                            <img src={footer_youtube} alt="youtube link" />
                        </Link>
                        <Link to="https://www.instagram.com/nirmal.kutiya.johalan/">
                            <img src={footer_instagram} alt="instagram link" />
                        </Link>
                        <Link href="https://www.facebook.com/nirmalkutiyajohalan">
                            <img src={footer_facebook} alt="facebook link" />
                        </Link>
                    </div>
                </section>
                <div className="footer-border"></div>
            </footer>
        </div>
    )
}