import React from "react";
import { CommonView } from "../common-view/CommonView";

export const NirmalPanth = () => {

    return (
        <CommonView cssId={'banner-image-origins'} title={'Origins'}>
            <p>
                The word ‘Nirmala’ is Sanskrit for ‘Clean’, ‘Pure’ and ‘A pure hearted person’. The word is used to describe
                those learned individuals on the Guru’s path who use their knowledge to educate Sikh Sangat. These educated
                individuals are known to us by the title ‘Nirmalay Sadhu’ or Nirmalay Sant’ or sometimes just as ‘Nirmalay’
                – which is the plural of the word ‘Nirmala’.
            </p>
            <p>
                Nirmalas Of Sri Guru Gobind Singh Ji Sri Guru Gobind Singh Ji felt that his Sikh soldiers had now become
                specialists in the use of weaponry and he now wished to broaden their understanding by introducing them to
                the knowledge of Sanskrit. This was required to spread the fundamental principles of Sikhism in India and
                abroad, as preached by Sri Guru Nanak Dev Ji. The concept of the ‘SANT SIPAHI’ was taking form.
            </p>
            <p>
                During Sri Guru Gobind Singh Ji’s stay at Poanta Sahib from 1685 to 1688 (Samvat 1742 to 1745) religious
                Diwans were held every morning and evening. Scholars and Pandits from far and near graced these Diwans and
                conversed to the sangat on the Vedas, Puranas and Shastras. Under the Guru’s instructions the educated ones
                translated the Sanskrit books into Braj, Sadha Bhasha and Punjabi so that the common man can expand his
                knowledge.
            </p>
            <p>
                Sri Guru Gobind Singh Ji asked Pandit Raghunath to teach Sanskrit to the Sikhs. Pandit Raghunath very
                politely refused because Sanskrit was ‘Dev Bhasha’ – the language of the Gods – and it could not be taught
                to people of lower castes. Hearing this, the Guru gave the following reply:

            </p>
        </CommonView>
    )

}
