import React from 'react';
import diamond_divider_black from '../../../global/images/diamond_divider_blackbg.gif';
import { ImageSlideshow } from '../../ImageSlideshow';
import carousel_image1 from '../../../global/images/top-carousel-images/1.jpg';
import carousel_image2 from '../../../global/images/top-carousel-images/2.jpg';
import carousel_image3 from '../../../global/images/top-carousel-images/3.jpg';
import carousel_image4 from '../../../global/images/top-carousel-images/4.jpg';
import carousel_image5 from '../../../global/images/top-carousel-images/5.jpg';
import carousel_image6 from '../../../global/images/top-carousel-images/6.jpg';

const homeImages = [
    carousel_image1,
    carousel_image2,
    carousel_image3,
    carousel_image4,
    carousel_image5,
    carousel_image6
]

export const WelcomeBanner = () => {

    return (
        <section className="banner-container-homepage">
            <div id="banner-quote-holder">
                <div id="banner-quote">ਸਾਧ ਸੰਗਤਿ ਅਸਥਾਨ ਜਗਮਗ ਨੂਰ ਹੈ ॥</div>
                <img className="black-diamond-divider"
                    src={diamond_divider_black}
                    alt="black diamond divider"
                />
            </div>
            <div id="banner-image-holder">
                <ImageSlideshow images={homeImages} interval={5000} />
            </div>
        </section>
    );

}
