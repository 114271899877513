import React from "react";
import { CommonView } from "../common-view/CommonView";

export const SikhGurus = () => {

    return (
        <CommonView cssId={'banner-image-das-satguru'} title={'The Sikh Gurus'}>
            <p>
                SatGuru Nanak received a Hukam (commandment) from the Almighty instructing him to spread a message of truth
                based on devotion to One God, honesty, and compassion. The soul of Guru Nanak passed on to nine successors,
                who elaborated on the first Guru's teachings to give form to this new religion. The final form was given by
                the tenth and last Guru, Sri Guru Gobind Singh Ji, who started the Khalsa initiation ceremony: initiated
                Sikhs formed a community of saint-soldiers known as Khalsa. Guru Gobind Singh Ji also made it clear that,
                after him, the Guru-eternal for Sikhs would be their holy scripture, Guru Granth Sahib.
            </p>
            <p>
                <strong> SRI GURU NANAK DEV JI MAHARAJ</strong><br />
                Born in 1469 to a Hindu family near the city of Lahore (now a part of Pakistan ), SatGuru Nanak Dev Ji was
                the founder of the Sikh faith. The young Guru Nanak Dev Ji enjoyed the company of holy men and engaged them
                in long discussions about the nature of God. Around the year 1500, SatGuru Nanak Dev Ji had a revelation
                from God; and shortly thereafter, he uttered the words:
            </p>
            <p><em> There is No Hindu, There is No Muslim</em></p>
            <p>
                This pronouncement was substantial as it referred to the day and age in which SatGuru Nanak Dev Ji lived:
                Hindus and Muslims of India constantly and bitterly fought each other over the issue of religion. The Guru
                meant to emphasize that, ultimately, in the eyes of God, it is not religion that determines a person's
                merits, but one's actions.
            </p>
            <p>
                The Guru witnessed the Mughal invasion of India , and saw the horrors inflicted upon the common people by
                the invaders. Though a pacifist, SatGuru Nanak Dev Ji did not hesitate to speak up against injustice:
            </p>
            <p><em> The kings are ravenous beasts, their ministers are dogs.</em></p>
            <p>
                <em> The Age is a Knife, and the Kings are Butchers</em><em>
                    <br />
                </em><em> In this dark night of evil, the moon of righteousness is nowhere visible.</em>
            </p>
            <p>
                SatGuru Nanak Dev Ji laid forth three basic principles by which every human being should abide:<br />
                1. Remember the name of God at all times.<br />
                2. Earn an honest living as a householder.<br />
                3. Share a portion of your earnings with the less fortunate.
            </p>
            <p>
                Besides rejecting the Hindu caste system, idolatry, and ritualism, SatGuru Nanak Dev Ji preached universal
                equality. In consistence with his message of equality, SatGuru Nanak Dev Ji scorned those who considered
                women to be evil and inferior to men by asking:
            </p>
            <p><em> Why should we call her inferior, when it is she who gives birth to great persons?</em></p>
            <p>
                SatGuru Nanak Dev Ji has been documented to have traveled across India and the Middle East to spread his
                message. Once, at Mecca , the Guru was resting with his feet pointing toward the holy shrine. When a Muslim
                priest angrily reprimanded the Guru for showing disrespect to God, the Guru replied, &quot;Kindly point my
                feet toward the place where God does not exist.&quot; Among the many philosophical foundations laid by Guru
                Nanak , his characterization of God, as illustrated by his visit to Mecca , is most recognizable. It forms
                the opening lines of the 1430 page Sikh holy scripture, Guru Granth Sahib. The translation is as follows:
            </p>
            <p>
                <em>
                    There is but One God, The Supreme Truth; The Ultimate Reality, The Creator, Without fear, Without
                    enemies, Timeless is His image, Without Birth, Self Created, By His grace revealed.
                </em>
            </p>
            <p>
                Like all the Gurus after him, SatGuru Nanak Dev Ji preached by example. During a time of great social
                disarray and religious decay, his message served as a fresh, uncorrupted approach toward spirituality and
                God. The message of the Guru took almost 240 years to unfold, and so, in accordance with the Will of God,
                the soul of SatGuru Nanak Dev Ji merged into the souls of his nine successors.
            </p>
            <p>
                <strong> SATGURU ANGAD DEV JI MAHARAJ</strong><br />
                Besides maintaining and upholding the traditions laid forth by SatGuru Nanak Dev Ji, the second Guru created
                the Gurmukhi script, a medium through which the writings and teachings of the Sikh gurus could be readily
                understood by their followers. By disassociating the Sikh tradition from Sanskrit influence, a script and
                language largely unknown to the masses, the Guru emphasized the universality and widespread accessibility of
                Sikh religious thought. Moreover, it solidified the idea that the Sikh faith started as a distinct and
                revealed religion.
            </p>
            <p>
                <strong> SATGURU AMAR DAS JI MAHARAJ </strong><br />
                The third Sikh Guru reinforced the teachings of the previous Gurus by organizing the construction of
                twenty-two centers of religious learning for the Sikhs. Like his predecessors, he sharply criticized the
                practice of sati<em> , </em> where widows immolated themselves on the funeral pyres of their dead husbands.
                The Guru also required that anyone wishing to meet him would have to first partake in the common kitchen,
                called Langar, as a sign of equality. The Emperor Akbar, Muslim ruler of India , himself followed this
                tradition before meeting with the Guru.
            </p>
            <p>
                <strong> SRI GURU RAM DAS JI MAHARAJ </strong><br />
                Founder of the city of Amritsar , site of the Golden Temple , Guru Ram Das worked to ensure the city's
                growth by encouraging commercial and trade ventures in the town. Soon, with the city flourishing as a trade
                center and place of pilgrimage, the Sikhs had a distinct religious center of their own.
            </p>
            <p>
                <strong> SRI GURU ARJAN DEV JI MAHARAJ </strong><br />
                The fifth Guru started the construction of the Golden Temple . To emphasize the universality of Sikhism, the
                foundation stone of the shrine was laid by a Muslim saint, named Mian Mir. Also, the temple featured four
                entrances to represent access to all communities. Guru Arjan compiled the Adi Granth, the Sikh scripture
                containing the writings of all the Gurus up until that time (the writings of the eighth Guru were added by
                Guru Gobind Singh). As another sign of the universality of Sikh philosophy, the Guru added the writings of
                several Muslim and Hindu saints, whose ideas corresponded to Sikh beliefs. With the passage of time, the
                Guru attracted a substantial following; and therefore, the Sikh community assumed a socio-political
                character. In 1606, Emperor Jehangir, the Muslim ruler of India , summoned the Guru to his court on the
                charge of blessing a rebellious relative of the Emperor's. Upon the Guru's refusal to embrace Islam to
                escape death, the fifth prophet of the Sikh religion was subject to inhumane torture and killed. Thus, the
                martyrdom tradition of Sikhism began with the martyrdom of the Guru himself. From this point forward,
                Sikhism began to form itself into a community of saint-soldiers.
            </p>
            <p>
                <strong> SRI GURU HAR GOBIND JI MAHARAJ </strong><br />
                Responsible for establishing idea of the inseparability of spiritual and temporal matters, the sixth Guru
                maintained an army for the purpose of protecting the poor and destroying tyrants. He constructed the Akal
                Takht, center of temporal affairs in the Sikh religion, across from the Golden Temple in Amritsar . By this
                time, the Sikh community was a full-fledged social, religious, and political entity.
            </p>
            <p>
                <strong> SRI GURU HAR RAI JI MAHARAJ </strong><br />
                The seventh Guru continued the mission of organizing the Sikhs into a military force that would be equipped
                and ready, both spiritually and physically, to counter the repressive Mughal empire.
            </p>
            <p>
                <strong> SRI GURU HAR KRISHAN</strong> <strong>JI MAHARAJ </strong><br />
                At only five years of age, the eighth Sikh Guru was the youngest. He worked to alleviate the suffering of
                the common man during a smallpox epidemic in Delhi , but succumbed to the disease himself at the age of
                eight.
            </p>
            <p>
                <strong> SRI GURU TEGH BAHADUR JI MAHARAJ </strong><br />
                The Mughal Emperor of India , Aurangzeb, attempted to consolidate India into one Islamic nation. In order to
                achieve this aim, he set out to virtually eliminate Hinduism from India . When the eighth Guru heard of this
                from a desperate group of Hindus, he challenged the Emperor that, in order to convert all the Hindus, the
                Guru himself would have to embrace Islam. When the Guru was imprisoned at the request of Aurangzeb in 1675,
                despite being forced to watch the torture and execution of two disciples, the Guru simply refused to concede
                to the Emperor's demand. Finally, the Guru was ordered to get beheaded. Unparalleled in the history of
                humankind, the martyrdom of Guru Teg Bahadur was an act of sacrifice for another religious community. The
                Guru's martyrdom served to awaken the collective conscience of the Sikh community, which was about to
                undergo a final transformation in the years to follow.
            </p>
            <p>
                <strong> SRI GURU GOBIND SINGH JI MAHARAJ </strong><br />
                Upon the death of his father, Guru Gobind Singh felt compelled to organize the Sikhs into a community of
                saint-soldiers. During the spring of 1699, the Guru called his followers for a special gathering. During the
                day, thousands of people assembled in front of a stage and a tent, out of which emerged the Guru to address
                the massive audience. With sword in hand, the Guru asked the congregation if anyone would be willing to
                sacrifice their head for him. Naturally, the audience was stunned by the Guru's request, and many followers
                began to disperse out of sheer terror. Still, the Guru pressed for one of his followers to give their life
                for him. Finally, one of the assembled stood, with hands folded, and approached the Guru in full submission.
                The lone disciple was led into the tent by the Guru. After some time, the Guru emerged with a blood-stained
                sword and asked for another head. Overcome with shock, the audience could not believe what they were seeing;
                however, another devoted follower stood and offered his head to the Guru. Eventually, with the same outcome,
                three more devoted disciples offered their lives to the Guru. After the fifth devotee was led into the tent,
                to the surprise of the massive audience, the Guru emerged with the five followers fully clothed in the
                uniform of the Khalsa. The Guru's demonstration symbolized a revitalization of the Sikh identity and the
                definitive evolution of the Sikh community into a community of saint-soldiers. After initiating the five
                &quot;beloved ones&quot; into the new order of the Khalsa, the Guru knelt before them and requested that
                they initiate him. In the annals of human history, such a transformation into a distinct and solidified
                community, culminating in the baptism of the prophet by his followers, remains a unique and defining moment.
            </p>
            <p>
                Guru Gobind Singh and his Khalsa army were engaged in several battles against the imperialist Mughal army
                during the Guru's life. Through the course of those turbulent times, the Guru gave his four children and his
                mother to the cause of righteousness; but nevertheless, the Khalsa stood firm as a distinct and sovereign
                entity, able to withstand the onslaught of a mighty enemy.
            </p>
            <p> In the face of persecution, the Guru wrote: </p>
            <p><strong> When all peaceful means of resolution have failed, it is righteous to draw the sword.</strong></p>
            <p>
                Before his death at the hands of an assailant in 1708, the Guru added the writings of Guru Tegh Bahadur to
                the Sikh scriptures, thereby giving a final revision to its form. The Guru also declared the lineage of
                living Gurus finished, and requested his followers to seek spiritual guidance from the <strong>
                    Guru Granth
                    Sahib
                </strong> . In essence, the light of Nanak, the first Guru, was to be forever enshrined within the
                pages of the <strong> Guru Granth Sahib. </strong> A separate volume, called Dasam Granth, features the
                writings of the tenth Sikh Guru.
            </p>
        </CommonView>
    )
}
