import React from 'react';

export const NoJsWarning = () => {

    return (
        <noscript>
            <p>
                You need to enable javascript for an unlimited experience on this website.
                If your browser does not have this enabled, you may be limited by your browsers capabilities. Thank you for
                your
                understanding.
            </p>
        </noscript>
    )
}

