import React from "react";
import diamond_divider_whitebg from "../../../global/images/diamond-divider_whitebg.gif";
import follow_us_youtube from "../../../global/images/follow-us-youtube.gif";
import follow_us_facebook from "../../../global/images/follow-us-facebook.gif";
import follow_us_instragram from "../../../global/images/follow-us-instagram.gif";
import { Link } from "react-router-dom";

export const FollowUs = () => {

    return (
        <section id="follow-us-container">

            <h1>Follow us</h1>
            <p>Stay up to date with news and events.</p>
            <img src={diamond_divider_whitebg} alt="diamond divider" />

            <div id="follow-us-image-holder">
                <Link to="https://www.youtube.com/channel/UCI1rpTU_zYrwaChpaEauX3Q" target="_blank">
                    <img src={follow_us_youtube} alt="follow us youtube" />
                </Link>
                <Link to="https://www.instagram.com/nirmal.kutiya.johalan/" target="_blank">
                    <img className="follow-us-container-not-first-img" src={follow_us_facebook} alt="follow us facebook" />
                </Link>
                <Link to="https://www.facebook.com/nirmalkutiyajohalan" target="_blank">
                    <img className="follow-us-container-not-first-img" src={follow_us_instragram} alt="follow us instagram" />
                </Link>
            </div>

        </section>
    )
}
