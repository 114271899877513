import React from "react";
import { CommonView } from "../common-view/CommonView";

export const GuruGranthSahib = () => {

    return (
        <CommonView cssId={'banner-image-sri-guru-granth-sahib-ji'}
            title={'Guru Granth Sahib Ji'}
        >
            <p>
                In 1604 Sri Guru Arjan Dev Ji , the fifth guru collected together the sacred writings of the first five gurus
                and also those of many enlightened Bhagats and compiled the Adi Granth, the Sikh holy scripture.
            </p>
            <p>
                Later on, Guru Gobind Singh Ji added the writings of the ninth guru, Guru Teg Bahadur. Guru Gobind Singh Ji
                declared that there would be no successor to him and that the next guru would be the Guru Granth Sahib. The
                light in that had existed in the the human forms of the ten gurus was passed on to shine in the Guru Granth
                Sahib.
            </p>
            <p>
                The Sikhs were instructed to recognise the Guru Granth Sahib Ji as their living Guru and respect it as so.
                Sikhs worship the Guru Granth Sahib Ji, which is the word of God- the Gurbani contained in it. The Gurbani
                originally came from God himself and was given to the Sikhs via the Gurus. Gurbani is the instruction of God
                to his diciples.
            </p>
            <p>Guru Gobind Singh Ji's compositions were compiled into a separate granth, called the Sri Dasam Granth. </p>
            <blockquote>
                "The Word, the Bani is Guru, and Guru is the Bani. <br />
                Within the Bani, the Ambrosial Nectar is contained."<br />
                <em>
                    (Sri Guru Granth Sahib Ji, <br />
                    Sri Guru Arjan Dev Ji, Raag Nat Naaraayan on Ang 982)
                </em>
            </blockquote>
            <blockquote>
                "O GurSikhs, know that the Bani, the Word of the True Guru, is true, absolutely true. <br />
                The Creator Lord Himself causes the Guru to chant it. "<br />
                <em>
                    (Sri Guru Granth Sahib Ji, <br />
                    Guru Raam Daas Ji <br />
                    Raag Gauree on Ang 308)
                </em>
            </blockquote>
        </CommonView>
    )

}
