import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { desktopMediaQuery, mobileMediaQuery, tabletMediaQuery } from '../../../global/redux-reducer/MediaQueryReducer';
import { Link } from 'react-router-dom'
import headerLogo from '../../../global/images/header-logo.png';
import headerLogoWriting from '../../../global/images/header-logo-writing.png';
import youtube from '../../../global/images/youtube.gif';
import facebook from '../../../global/images/facebook.gif';
import instagram from '../../../global/images/instagram.gif';
import burger_menu from '../../../global/images/menu-burger.png';
import { DesktopMenu, MobileMenu } from './menu/Menu';
import { menuItems } from './menu/MenuItems';

export const HeaderBar = () => {

    const mediaType = useSelector(state => state.mediaQueryReducer.mediaType);

    const [isOpen, setIsOpen] = useState(false);
    const toggleDropdown = () => setIsOpen(!isOpen);

    return (
        <div>
            {(mediaType === desktopMediaQuery || mediaType === tabletMediaQuery) &&
                <header className="header" id="main-header">
                    <div className="header-corner-diamond"></div>
                    <div className="header-logo" id="main-header-logo">
                        <img src={headerLogo}
                            alt="Gurdwara Amrit Parchar Dharmik Diwan UK Logo" />
                    </div>
                    <div className="header-logo-writing" id="main-header-logo-writing">
                        <img src={headerLogoWriting}
                            alt="Gurdwara Amrit Parchar Dharmik Diwan UK" />
                    </div>
                    <DesktopMenu menuItems={menuItems} />
                    <section id="social-media-icon-section">
                        <Link to="https://www.instagram.com/nirmal.kutiya.johalan/">
                            <img src={instagram} alt='instagram logo link' />
                        </Link>
                        <Link to="https://www.facebook.com/nirmalkutiyajohalan">
                            <img src={facebook} alt='facebook logo link' />
                        </Link>
                        <Link to="https://www.youtube.com/channel/UCI1rpTU_zYrwaChpaEauX3Q">
                            <img src={youtube} alt='youtube logo link' />
                        </Link>
                    </section>
                    <div className="header-corner-diamond"></div>
                </header>
            }

            {mediaType === mobileMediaQuery &&
                <div className='smallscreen-header-container'>
                    <section id="smallscreen-logo">
                        <img src={headerLogo} alt="Gurdwara Amrit Parchar Dharmik Diwan UK Logo" />
                        <br />
                        <img src={headerLogoWriting} alt="Gurdwara Amrit Parchar Dharmik Diwan UK" />
                        <br />
                    </section>
                    <section id="burger-menu" onClick={toggleDropdown}>
                        <img src={burger_menu} alt="menu toggle button" />
                    </section>
                    {isOpen &&
                        <MobileMenu menuItems={menuItems} />
                    }
                </div>
            }

        </div>
    );
}
