import React from "react";
import { CommonView } from "../common-view/CommonView";

export const SantBabaBasantSinghJi = () => {

    return (
        <CommonView cssId={'banner-image-sant-basant-singh'} title={'Sant Baba Basant Singh Ji'}>
            <p>
                Sant Baba Basant Singh Ji was born in village Marula, District Hoshiarpur on 10 November 1885. Marula is a
                small village four kilometres from village Jiyan and situated to the north of the city of Hoshiarpur. Sant
                Baba Basant Singh Ji would visit the Gurdwara daily and Sant Baba Basant Singh Ji’s mother would teach him
                to recite Gurbani continuously.
            </p>
            <p>
                A very significant event in the life of Sant Baba Basant Singh Ji occurred when they were taken into the fold
                of Sri Maan 108 Braham Giani Sant Baba Harnam Singh Ji Maharaj of Jiyan, District Hoshiarpur.
            </p>
            <p>
                Sant Baba Harnam Singh Ji blessed Sant Baba Basant Singh Ji and they renounced all worldly obligations and
                began performing strict meditation. Sant Baba Basant Singh Ji learnt the right way of worshiping the divine,
                how to recite Gurbani and remembrance of Waheguru Ji. With the blessings of Sant Baba Harnam Singh Ji, Sant
                Baba Basant Singh Ji was baptised with Amrit. They would remain in Samadhi – deep mediation for 24 hours a
                day and they would serve their Gurudev with full devotion.
            </p>
            <p>
                Sant Baba Harnam Singh Ji transferred their entire sovereignty to Sant Baba Basant Singh Ji and predicted
                that this devout soul would have a large following and they would be respected by Rajas and Maharajas. Sant
                Baba Harnam Singh Ji gave their blessings, “Sant! Your penance here is complete. You have to now spread the
                message of the Almighty to the world. You have to bless them with the teachings of Sri Guru Granth Sahib Ji
                and administer the Amrit of the double-edged sword”.
            </p>
            <p>
                Sant Baba Basant Singh Ji obeyed the orders of Sant Baba Harnam Singh Ji. Sant Baba Basant Singh Ji travelled
                to many different places preaching to people to have faith and devotion in Sri Guru Granth Sahib Ji. Leading
                the sangat to enlightenment and baptizing many with Sri Guru Gobind Singh Ji’s Amrit. Sant Baba Basant Singh
                Ji finally reached village Johalan near Jalandhar in 1920 where they settled.
            </p>
            <p>
                Sant Baba Basant Singh Ji attracted a huge following and soon became recognised for living and practising
                what they preached. From local villagers, small famers to big landowners, soldiers, army generals,
                government officials, doctors and engineers all soon became followers of Sant Baba Basant Singh Ji. Those
                who had met with them were inevitably drawn to Gurbani and encouraged to become baptized Sikhs. No matter
                how high the rank of an officer, this detached Sadhu considered him a part of the sangat.
            </p>
            <p>
                Sant Baba Basant Singh Ji merged with the eternal supreme on 9 November 1971 and Sant Baba Gian Singh Ji was
                appointed as the Head of Nirmal Kutiya Johalan
            </p>
        </CommonView>
    )

}
