import React from "react";
import { CommonView } from "../common-view/CommonView";

export const Oldbury = () => {

    return (
        <CommonView cssId={'banner-image-oldbury'} title={'Oldbury'}>
            <p className="subtitle-emphasis">
                Gurdwara Amrit Parchar Dharmik Diwan &U+002d <br />
                Oldbury, UK
            </p>

            <p>
                Sangat living in West Midlands towns and cities who were initiated into the Khalsa Panth by taking Amrit under the guidance of Sant Baba Gian Singh Ji were keen to practice and promote the Maryada that was passed down by Sri Guru Gobind Singh Ji via Head Panj Pyare - Bhai Daya Singh Ji through the Hoti Mardarn lineage.
            </p>

            <p>
                The Sangat around Bradford were very fortunate to have a Gurdwara Sahib where they could practice this Maryada and conduct Parchar on Sri Guru Granth Sahib Ji and encourage fellow Sikhs to take Amrit.
            </p>

            <p>
                Sangat humbly requested Sant Baba Gian Singh Ji to establish a similar Gurdwara Sahib in the West Midlands area that would be managed under their patronage. Sant Baba Gian Singh ji said, “It is the Pyaar (love) of the Sangat to think in this manner. They should seek the support and blessings of the Almighty and start this endeavour.” Ardaas was completed in the presence of Sri Guru Granth Sahib Ji.
            </p>

            <p>
                Sevadars soon found a property with two buildings and parking for circa 100 cars which was purchased. Nineteen Gursikhs from all over England contributed to the purchase of the building and it was agreed that the Marayda would mirror that of Gurdwara Amrit Parchar Dharmik Diwan Bradford as per the Hukam (instructions) of Sant Baba Gian Singh Ji.
            </p>

            <p>
                The Gurdwara Sahib was opened by Sant Baba Gian Singh Ji on 21st September 1987 with Arambh (start) of Five Akhands Paaths. After the Bhog of five Akhand Paaths, Panj Pyare hoisted the Nishan Sahib at the Gurdwara Sahib. This Gurdwara was named ‘Gurdwara Amrit Parchar Dharmik Diwan, Birmingham (UK) by Sant Baba Gian Singh Ji.
            </p>

            <p>
                This Gurdwara is now under the patronage of Sant Baba Jeet Singh Ji, Current Mukhi of Nirmal Kutiya Johalan. Under their guidance the Gurdwara Sahib conducts weekly Akhand Paaths, regular Amrit Sanchars and observes the Maryada as instructed by Sant Baba Gian Singh Ji.
            </p>

            <h2>Current Vacanices</h2>

            <a href="~/Content/images/oldbury_requirement.jpg" target="_blank">Gurdwara Assistants</a>
        </CommonView>
    )

}