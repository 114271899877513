import React from "react";
import { CommonView } from "../common-view/CommonView";

export const Bradford = () => {

    return (
        <CommonView cssId={'banner-image-bradford'} title={'Bradford'}>
            <p className="subtitle-emphasis">
                Gurdwara Amrit Parchar Dharmik Diwan – <br />
                Bradford, UK
            </p>

            <p>
                With the growth of the Sikh community in the United Kingdom over the 1960s and 1970s, there became a pressing need to promote understanding and adherence to Naam Japna
                (Contemplation and Meditation on the Name of the Lord), Rehat Maryada (Practical Code of Conduct) and Amrit Sanchar (Initiation into the Khalsa Panth).
                In July 1980. Sri Maan 108 Sant Baba Gian Singh Ji blessed the Sangat with their presence. In the four months that Baba Ji stayed, over 500 Sikhs received their Initiation
                into the Order of the Khalsa.
            </p>

            <p>
                Sant Ji a significant amount of their time in the town of Bradford where they attracted many devotee's join the congregration and then become fully fledged member's
                of the Khalsa panth by partaking Amrit. After recving numerous requests from the Sangat, Sant ji announced the opening of a new Gurdwara in the heart of Bradford.
                The Sangat had been regularly holding religious gatherings at each other's houses and needed a more permanent and suitable solution.
            </p>

            <p>
                With the Grace of Guru Granth Sahib Ji, a building was found which seemed suitable for a Gurdwara Sahib. The building was formerly a Zion Chapel and
                was to Sant Baba Gian Singh Ji’s liking, as they proclaimed “Someone has previously done penance here”. At one point it seemed as if the building would slip through the hands
                of the Sangat, however, Sant Baba Gian Singh Ji told the sangat not to worry, as “Birthi kadae na hovaee jan ki ardaas”
                ( The prayer of the Lord's humble servant is never offered in vain ).
            </p>

            <p>
                The Gurdwara sahib was officially opened by Sant Baba Gian Singh Ji on the 24th October 1982.
            </p>
        </CommonView>
    )

}