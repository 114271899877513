import React from "react";
import { CommonView } from "../common-view/CommonView";
import guru_gobind_singh_jee from "../../../global/images/gurugobindsinghjee.jpg";

export const FaithView = () => {

    return (
        <CommonView cssId={'banner-image-faith'}
            title={'Faith'}
        >
            <div>
                <p>
                    Over twenty million Sikhs follow a revealed, distinct, and unique religion born five centuries ago in the
                    Punjab region of northern India . Between 1469 and 1708, the ten Gurus preached a simple message of truth,
                    devotion to God, and universal equality.
                </p>
                <p>
                    Often mistaken as a combination of Hinduism and Islam, the Sikh religion can be characterized as a
                    completely independent faith. Sikhism recognizes the equality between both genders and all religions,
                    prohibits the abuse of any intoxicants, and encourages an honest, truthful living.
                </p>
                <p>
                    Sikhs have their own holy scripture, the Sri Guru Granth Sahib. Written, composed, and compiled by the Sikh
                    Gurus themselves, the Guru Granth Sahib serves as the ultimate source of spiritual guidance for Sikhs. The
                    Guru Granth Sahib not only contains writings of the Gurus, but that of other famous Saints of various faiths
                    and backgrounds, such as Sheikh Farid, a muslim saint from Punjab and Bhagat Parmanand, a Brahmin from
                    Maharashtra.
                </p>
                <p>
                    Members of the Sikh community are mainly concentrated in their homeland, Punjab , although substantial Sikh
                    populations exist throughout the rest of India and the world. There is a large number of Sikhs who live in
                    the UK , mainly concentrated in towns and cities such as Bradford , Leeds and Birmingham .
                </p>
                <p>
                    Punjabi, a variant of the Hindi language with some Persian influence, is the spoken and written language of
                    the Sikh people. Male members of the Sikh religion use the name Singh (lion) as their middle or last name,
                    while females use the name Kaur (princess).
                </p>
                <p>
                    Sikhs tend to be industrious and pioneering, which accounts for their general success wherever they live and
                    settle. The hard-working nature of the Sikhs is derived from their religion, which can be best characterized
                    as a faith of unlimited optimism.
                </p>
                <p>
                    The founder of the Sikh religion was Sri Guru Nanak Dev Ji and the founder of the Khalsa Sri Guru Gobind
                    Singh Ji with the last guru in human form being Sri Guru Gobind Singh Ji, who then gave the guruship to the
                    Sri Guru Granth Sahib after he departed from this earth.
                </p>
                <div className="main-content-img-container">
                    <img src={guru_gobind_singh_jee} width="100%" height="auto" alt="guru gobind singh ji" />
                </div>
            </div>
        </CommonView>
    )

}