import React from "react";
import { CommonView } from "../common-view/CommonView";

export const SantBabaHarbhajanSinghJi = () => {

    return (
        <CommonView cssId={'banner-image-sant-harbhajan-singh'} title={'Sant Baba Harbhajan Singh Ji Virakt'}>
            <p>
                Sant Baba Harbhajan Singh Ji ‘Virakat’ was born in village Marula in the year 1929. They were only seven
                years old when their grandfather Sardar Jeen Singh and father Sardar Sunder Singh brought them into the fold
                of Sant Baba Basant Singh Ji.
                <p>
                    Virakat Ji spent their entire life at Nirmal Kutiya Johalan renouncing all worldly wishes and served
                    selflessly at the Kutiya. They worked within the Kutiya and attended to the cattle and horses. Sant Baba
                    Harbhajan Singh Ji was named ‘VIRAKAT JI’ by Sant Baba Basant Singh Ji and they are remembered as
                    ‘Virakat Ji Maharaj’ by the sangat. Virakat Ji was a student at the local Government School where they
                    studied Sikh history.
                </p>
                <p>
                    Sant Baba Gian Singh Ji blessed Virakat Ji with spiritual knowledge. Virakat Ji’s love for Gurbani was
                    evident by the fact that they were able to recite by heart GUR BILAS of the Sixth Guru, GUR BILAS of the
                    Tenth Guru, BIJEY MUKT, all SOORAJ PARKASH’S and DHARAM SHASTRA. Every day they recited 25 Baani’s from
                    memory. Virakat Ji was a very knowledgeable Sadhu and guided the other Sadhus in their spiritual
                    pursuit.
                </p>
                <p>
                    Sant Baba Basant Singh Ji recognised the level of spiritual knowledge attained by Virakat Ji and in 1955
                    blessed them with a GATHEE. Sant Baba Basant Singh Ji instructed their beloved disciple Sant Baba Gian
                    Singh Ji to take care of Virakat Ji, as they believed Virakat Ji was a soldier from Sachkhand.
                </p>
                <p>
                    Virakat Ji was given the responsibility of langar at the Kutiya by Sant Baba Basant Singh Ji. They
                    performed this seva whole heartedly. Virakat Ji served Sant Baba Gian Singh Ji with love and devotion
                    whilst serving as the assistant Holy Saint.
                </p>
                <p>
                    Virakat Ji was often remembered for directing the sangat to the Darbar Hall to pray before Sri Guru
                    Granth Sahib Ji for the sangat’s wishes to be fulfilled.
                </p>
                <p>
                    The soul of Sant Baba Gian Singh Ji Maharaj merged with the eternal supreme on 6 November 2001. On 18
                    November 2001 in the presence of thousands of members of the sangat, Sant Baba Harbhajan Singh Ji
                    ‘Virakat’ became the Head of Nirmal Kutiya Johalan. Sant Baba Jeet Singh Ji was also appointed assistant
                    Holy Saint. Virakat Ji continued to follow the discipline of Sant Baba Gian Singh Ji and upheld the
                    maryada of Nirmal Kutiya Johalan, whilst baptising thousands of members of the sangat.
                </p>
                <p>
                    Sant Baba Harbhajan Singh Ji ‘Virakat’s soul merged with the eternal supreme on 8 March 2002. As per
                    tradition they were immersed in the waters of the river Sutlej at Gurdwara Bhabhor Sahib, in District
                    Ropar. Accordingly, Sant Baba Jeet Singh Ji became Head of Nirmal Kutiya Johalan in the presence of the
                    Sangat
                </p>
            </p>
        </CommonView>
    )

}
