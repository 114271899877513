import React from 'react';
import { WelcomeBanner } from './WelcomeBanner';
import { Introduction } from './Introduction';
import { Asthaan } from './Asthaan';
import { FollowUs } from './FollowUs';

export const Home = () => {

    return (
        <div>
            <WelcomeBanner />
            <div style={{ position: 'relative' }}>
                <div id="welcome-divider">
                    <hr />
                </div>
            </div>
            <Introduction />
            <Asthaan />
            <FollowUs />
        </div>

    );

}
