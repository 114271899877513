import React from "react";
import { CommonView } from "../common-view/CommonView";

export const HighWycombe = () => {

    return (
        <CommonView cssId={'banner-image-high-wycombe'} title={'High Wycombe'}>
            <p className="subtitle-emphasis">
                Gurdwara Amrit Parchar Dharmik Diwan – <br />
                High Wycombe, UK
            </p>

            <p>
                Sri Maan 108 Sant Baba Gian Singh Ji Maharaj during one of his tours to England, visited the city of Wolverhampton.
                The devotees of High Wycombe came to pay their homage to the Holy Saint. They requested Sant Baba Ji, “Maharaj Ji! There is no Gurdwara in our city.
                Please bless us and establish a Gurdwara in our city as well”.
            </p>

            <p>
                Sant Baba Ji advised, “First, all of you accept the nectar of the two-edged sword blessed by Sri Guru Gobind Singh Ji and only then we will think about your request”.
                These words influenced the devotees who almost immediately obtained baptism. Their attire changed. They observed that the majesty of a Sikh was in wearing a turban.
                They also found a suitable building and decided to request Sant Baba Ji to bless them with a Gurdwara Sahib. Sant Baba Ji was kind and compassionate. On 4 September 1993 an Akhand Path
                was started. The Bhog of this Akhand Path was undertaken on 6 September 1993 in the presence of Sri Maan 108 Sant Baba Gian Singh Ji Maharaj and a congregation of devotees of England.
            </p>

            <p>
                With the guidance of Sant Baba Ji the Nishan Sahib was hoisted ceremoniously. Religious hymns were sung and discourses were undertaken thanking the Holy Saint for his generosity.
                The eyes of the sangat were in tears and they considered this whole happening as a dream. The sangat said that they were fortunate to have a religious guide in the form of
                Sri Maan 108 Sant Baba Gian Singh Ji Maharaj who was instrumental in providing them a sacred place for recitation of Gurbani, listening to the holy hymns and meditation on the Lord.
            </p>

            <p>
                Sant Baba Ji said on this occasion, “This sacred place has been established only due to the prayers of the sangat”.
            </p>

            <p>
                Sri Maan 108 Sant Baba Gian Singh Ji Maharaj established two other Gurdwaras in the UK, keeping in view the need and the request of the sangat.
                This ensured that the local sangat developed the love for Gurbani and a connection with Sikhism. The first was Gurdwara Amrit Parchar Dharmik Diwan Bradford, established in 1982,
                followed by Gurdwara Amrit Parchar Dharmik Diwan Oldbury in 1987.
            </p>
        </CommonView>
    )

}