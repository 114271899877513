import React from "react"
import goldDivider from '../../../global/images/diamond-divider_goldbg.png';

export const Introduction = () => {

    return (
        <section id="welcome-container">
            <div id="welcome-container-left-diamond"></div>
            <section id="welcome-section">
                <h1>Waheguru ji ka Khalsa,<br />Waheguru ji ki Fathe.</h1>
                <h2>Welcome to the official site of the UK branch of Gurdwara Amrit Parchar Dharmik Diwan</h2>
                <img src={goldDivider} alt="divider" />
                <p>
                    A part of the Nirmal Sampardai of the Khalsa panth, this institution was founded by Sri Maan Sant Baba Basant Singh Ji in 1920 but its origin's
                    stretch back to the founder of the Sikh faith SatGuru Nanak Dev Ji Maharaj.
                </p>
                <p>
                    We endeavor to provide the users of this website with all the information necessary to catch a glimpse of the divinity that Nirmal Kutiya Johalan enshrines. The website has two main sections and will have many more as we grow our online presence. The faith section has an overview of the Sikh faith and the founders section develops on this with a page dedicated to each Saint of Nirnal Kutiya Johalan.
                </p>
            </section>
            <div id="welcome-container-right-diamond"></div>
        </section>
    )
}

