import { useState } from 'react';
import { Link } from 'react-router-dom'

const getChildItems = ({ id, menuItems }) => {
    return menuItems.filter(x => x && x.parentId === id);
}

const containsChildren = ({ id, menuItems }) => {
    if (!id || !menuItems) {
        return false;
    } else {
        return menuItems.some(x => x && x.parentId === id);
    }
}

const DesktopChildItems = ({ items }) => {
    return (
        <ul>
            {items
                .map(({ name, link }) => (
                    <li key={name}>
                        <Link className="sub-navbar nav-menu" to={link}>{name}</Link>
                    </li>
                ))
            }
        </ul>
    )
}

const MobileChildItems = ({ items }) => {

    return (
        <ul style={{ display: 'block' }}>
            {items
                .map(({ name, link }) => (
                    <li key={name}>
                        <Link className="sub-navbar nav-menu" to={link}>{name}</Link>
                    </li>
                ))
            }
        </ul>
    )
}

export const MenuItems = ({ menuItems, isDesktop }) => {
    const [openChildItemId, setOpenChildItemId] = useState(null);

    const handleItemClick = (id, event) => {
        if (!isDesktop) {
            event.stopPropagation();
            setOpenChildItemId(openChildItemId === id ? null : id);
        }
    };

    return (
        menuItems
            .filter(x => x && x.id)
            .map(({ name, link, id }) => (
                <li key={name}>
                    <Link className="navbar nav-menu" to={link} onClick={(clickEvent) => handleItemClick(id, clickEvent)}>{name}</Link>
                    {isDesktop && containsChildren({ id, menuItems }) &&
                        <DesktopChildItems items={getChildItems({ id, menuItems })}></DesktopChildItems>
                    }
                    {!isDesktop && containsChildren({ id, menuItems }) && openChildItemId === id &&
                        <MobileChildItems items={getChildItems({ id, menuItems })}></MobileChildItems>
                    }
                </li>
            ))
    )
}

export const DesktopMenu = ({ menuItems }) => {

    return (
        <nav id="desktop-navbar" >
            <ul>
                <MenuItems menuItems={menuItems} isDesktop={true} />
            </ul>
        </nav>
    );
}

export const MobileMenu = ({ menuItems }) => {

    return (
        <nav id="smallscreen-menu" style={{ display: 'block' }}>
            <ul>
                <MenuItems menuItems={menuItems} isDesktop={false} />
            </ul>
        </nav>
    );
}