export const ROUTE_FAITH_KEY = "Faith_Route";
export const ROUTE_ORIGINS_KEY = "Nirmal_Panth_Route";
export const ROUTE_ASTHAAN_KEY = "Asthaan_Route";

export const ROUTE_FAITH_URL = "/faith";
export const ROUTE_SIKH_GURU_SAHIBS_URL = ROUTE_FAITH_URL + '/sikh-guru-sahibs';
export const ROUTE_GURU_GRANTH_SAHIB_JI_URL = ROUTE_FAITH_URL + '/guru-granth-sahib-ji';
export const ROUTE_KHALSA_URL = ROUTE_FAITH_URL + '/khalsa';

export const ROUTE_ORIGINS_URL = "/nirmal-panth";
export const ROUTE_SANT_BABA_HARNAM_SINGH_JI_URL = ROUTE_ORIGINS_URL + '/sant-baba-harnam-singh-ji';
export const ROUTE_SANT_BABA_BASANT_SINGH_JI_URL = ROUTE_ORIGINS_URL + '/sant-baba-basant-singh-ji';
export const ROUTE_SANT_BABA_GIAN_SINGH_JI_URL = ROUTE_ORIGINS_URL + '/sant-baba-gian-singh-ji';
export const ROUTE_SANT_BABA_HARBHAJAN_SINGH_JI_URL = ROUTE_ORIGINS_URL + '/sant-baba-harbhajan-singh-ji';
export const ROUTE_SANT_BABA_JEET_SINGH_JI_URL = ROUTE_ORIGINS_URL + '/sant-baba-jeet-singh-ji';

export const ROUTE_BRADFORD_URL = '/bradford';
export const ROUTE_HIGH_WYCOMBE_URL = '/high-wycombe';
export const ROUTE_OLDBURY_URL = '/oldbury';

export class AsthaanURLs {

    static bradfordURL = ROUTE_BRADFORD_URL;
    static highWycombeURL = ROUTE_HIGH_WYCOMBE_URL;
    static oldburyURL = ROUTE_OLDBURY_URL;

}
