import React from "react";
import { CommonView } from "../common-view/CommonView";

export const SantBabaJeetSinghJi = () => {

    return (
        <CommonView cssId={'banner-image-sant-jeet-singh'} title={'Sant Baba Jeet Singh Ji'}>
            <p>
                Current Head of Nirmal Kutiya Johalan. Sant Baba Jeet Singh Ji was born in village Kishankot (near Sri
                Hargobindpur) Tehsil Batala, District Gurdaspur on 1 June 1956.  In the above photograph sat to the left of
                Sant Baba Jeet Singh Ji is their assistant Sant Baba Gurmeet Singh Ji.
            </p>
            <p>
                In the year 1975, Sant Baba Gian Singh Ji Maharaj took Sant Baba Jeet Singh Ji under their fold. Sant Baba
                Jeet Singh Ji served Sant Baba Gian Singh Ji and Sant Baba Harbhajan Singh Ji ‘Virakat’ with love and
                devotion. They served Sant Baba Gian Singh Ji with extreme humbleness. Their Sewa included washing their
                VASTAAR, cleaning the floors and serving langar to Sant Baba Gian Singh Ji and the sangat.
            </p>
            <p>
                Sant Baba Gian Singh Ji appointed Sant Baba Jeet Singh Ji the fourth Holy Saint and bestowed him with the
                responsibility of the langar sewa at Nirmal Kutiya Johalan. Similarly Sant Baba Basant Singh Ji made Sant
                Baba Harbhajan Singh Ji ‘Virakat’ in charge of the langar sewa many years earlier.
            </p>
            <p>
                After the soul of Sant Baba Gian Singh Ji merged with the eternal supreme, the complete attention of Sant
                Baba Jeet Singh Ji was directed towards serving Sant Baba Harbhajan Singh Ji ‘Virakat’ with all humility.
                Sant Baba Harbhajan Singh Ji ‘Virakat’s soul merged with the eternal supreme on 8 March 2002 and Sant Baba
                Jeet Singh Ji became the Head of Nirmal Kutiya Johalan in the presence of the sangat.
            </p>
        </CommonView>
    )

}
