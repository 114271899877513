import { ROUTE_ASTHAAN_KEY, ROUTE_BRADFORD_URL, ROUTE_FAITH_KEY, ROUTE_FAITH_URL, ROUTE_GURU_GRANTH_SAHIB_JI_URL, ROUTE_HIGH_WYCOMBE_URL, ROUTE_KHALSA_URL, ROUTE_OLDBURY_URL, ROUTE_ORIGINS_KEY, ROUTE_ORIGINS_URL, ROUTE_SANT_BABA_BASANT_SINGH_JI_URL, ROUTE_SANT_BABA_GIAN_SINGH_JI_URL, ROUTE_SANT_BABA_HARBHAJAN_SINGH_JI_URL, ROUTE_SANT_BABA_HARNAM_SINGH_JI_URL, ROUTE_SANT_BABA_JEET_SINGH_JI_URL, ROUTE_SIKH_GURU_SAHIBS_URL } from "../../../../global/Routes";

export const menuItems = [
    {
        id: 'Home_Route',
        name: 'Home',
        link: '/home',
        parentId: null,
    },
    {
        id: ROUTE_FAITH_KEY,
        name: 'Faith',
        link: ROUTE_FAITH_URL,
        parentId: null,
    },
    {
        name: 'Sikh Faith',
        link: ROUTE_FAITH_URL,
        parentId: ROUTE_FAITH_KEY,
    },
    {
        name: 'Sikh Gurus',
        link: ROUTE_SIKH_GURU_SAHIBS_URL,
        parentId: ROUTE_FAITH_KEY,
    },
    {
        name: 'Guru Granth Sahib Ji',
        link: ROUTE_GURU_GRANTH_SAHIB_JI_URL,
        parentId: ROUTE_FAITH_KEY,
    },
    {
        name: 'The Khalsa',
        link: ROUTE_KHALSA_URL,
        parentId: ROUTE_FAITH_KEY,
    },
    {
        id: ROUTE_ORIGINS_KEY,
        name: 'Founders',
        link: ROUTE_ORIGINS_URL,
        parentId: null,
    },
    {
        name: 'Nirmal Panth',
        link: ROUTE_ORIGINS_URL,
        parentId: ROUTE_ORIGINS_KEY
    },
    {
        name: 'Sant Baba Harnam Singh Ji',
        link: ROUTE_SANT_BABA_HARNAM_SINGH_JI_URL,
        parentId: ROUTE_ORIGINS_KEY
    },
    {
        name: 'Sant Baba Basant Singh Ji',
        link: ROUTE_SANT_BABA_BASANT_SINGH_JI_URL,
        parentId: ROUTE_ORIGINS_KEY
    },
    {
        name: 'Sant Baba Gian Singh Ji',
        link: ROUTE_SANT_BABA_GIAN_SINGH_JI_URL,
        parentId: ROUTE_ORIGINS_KEY
    },
    {
        name: 'Sant Baba Harbhajan Virakat Singh Ji',
        link: ROUTE_SANT_BABA_HARBHAJAN_SINGH_JI_URL,
        parentId: ROUTE_ORIGINS_KEY
    },
    {
        name: 'Sant Baba Jeet Singh Ji',
        link: ROUTE_SANT_BABA_JEET_SINGH_JI_URL,
        parentId: ROUTE_ORIGINS_KEY
    },
    {
        id: ROUTE_ASTHAAN_KEY,
        name: 'Asthaan',
        link: ROUTE_BRADFORD_URL,
        parentId: null,
    },
    {
        name: 'Bradford',
        link: ROUTE_BRADFORD_URL,
        parentId: ROUTE_ASTHAAN_KEY
    },
    {
        name: 'High Wycombe',
        link: ROUTE_HIGH_WYCOMBE_URL,
        parentId: ROUTE_ASTHAAN_KEY
    },
    {
        name: 'Oldbury',
        link: ROUTE_OLDBURY_URL,
        parentId: ROUTE_ASTHAAN_KEY
    },
];
