import React from "react";
import diamond_divider_blackbg from "../../../global/images/diamond-divider_greybg.gif";
import { AsthaanURLs } from "../../../global/Routes";
import { Link } from "react-router-dom";


export const Asthaan = () => {

    return (
        <section id="asthaan-main-container">
            <h1>UK Asthaans</h1>
            <p><i>The three Gurdwaras established by Sri Maan Sant Baba Gian Singh Ji:</i></p>
            <img src={diamond_divider_blackbg} alt="diamond divider" />
            <br />
            <br />
            <section id="asthaan-holder">
                <article className="asthaan">
                    <div className="diamond">
                        <div id="diamond-text-bradford">Bradford</div>
                    </div>
                    <br />
                    <h3>Gurdwara Amrit Parchar Dharmik Diwan Bradford</h3>
                    <p>Peckover St, <br />Bradford, <br />BD1 5BD</p>
                    <Link to={AsthaanURLs.bradfordURL} >Bradford</Link>
                </article>
                <article className="asthaan">
                    <div className="diamond">
                        <div className="diamond-text">Oldbury</div>
                    </div>
                    <br />
                    <h3>Gurdwara Amrit Parchar Dharmik Diwan Oldbury</h3>
                    <p>65 Birmingham Rd, <br />Oldbury, <br />B69 4EH</p>
                    <Link to={AsthaanURLs.oldburyURL} >Oldbury</Link>
                </article>
                <article className="asthaan">
                    <div className="diamond">
                        <div id="diamond-text-high-wycombe">High Wycombe</div>
                    </div>
                    <br />
                    <h3>Gurdwara Amrit Parchar Dharmik Diwan High Wycombe</h3>
                    <p>Brook St, High Wycombe<br />HP11 2EQ</p>
                    <Link to={AsthaanURLs.highWycombeURL} >High Wycombe</Link>
                </article>
            </section>
        </section>

    )

}