import { FetchData } from "../components/FetchData";
import { Home } from "../components/views/home/Home";
import { Bradford } from "../components/views/asthaan/Bradford";
import { HighWycombe } from "../components/views/asthaan/HighWycombe";
import { Oldbury } from "../components/views/asthaan/Oldburys";
import { FaithView } from "../components/views/faith/FaithView";
import { GuruGranthSahib } from "../components/views/faith/GuruGranthSahib";
import { Khalsa } from "../components/views/faith/Khalsa";
import { SikhGurus } from "../components/views/faith/SikhGurus";
import { NirmalPanth } from "../components/views/founders/NirmalPanth";
import { SantBabaBasantSinghJi } from "../components/views/founders/SantBabaBasantSinghJi";
import { SantBabaGianSinghJi } from "../components/views/founders/SantBabaGianSinghJi";
import { SantBabaHarbhajanSinghJi } from "../components/views/founders/SantBabaHarbhajanSinghJi";
import { SantBabaHarnamSinghJi } from "../components/views/founders/SantBabaHarnamSinghJi";
import { SantBabaJeetSinghJi } from "../components/views/founders/SantBabaJeetSinghJi";
import { ROUTE_BRADFORD_URL, ROUTE_FAITH_URL, ROUTE_GURU_GRANTH_SAHIB_JI_URL, ROUTE_HIGH_WYCOMBE_URL, ROUTE_KHALSA_URL, ROUTE_OLDBURY_URL, ROUTE_ORIGINS_URL, ROUTE_SANT_BABA_BASANT_SINGH_JI_URL, ROUTE_SANT_BABA_GIAN_SINGH_JI_URL, ROUTE_SANT_BABA_HARBHAJAN_SINGH_JI_URL, ROUTE_SANT_BABA_HARNAM_SINGH_JI_URL, ROUTE_SANT_BABA_JEET_SINGH_JI_URL, ROUTE_SIKH_GURU_SAHIBS_URL } from "./Routes";

const AppRoutes = [
    {
        index: true,
        element: <Home />
    },
    {
        path: '/home',
        element: <Home />
    },
    {
        path: '/fetch-data',
        element: <FetchData />
    },
    {
        path: ROUTE_FAITH_URL,
        element: <FaithView />
    },
    {
        path: ROUTE_SIKH_GURU_SAHIBS_URL,
        element: <SikhGurus />
    },
    {
        path: ROUTE_GURU_GRANTH_SAHIB_JI_URL,
        element: <GuruGranthSahib />
    },
    {
        path: ROUTE_KHALSA_URL,
        element: <Khalsa />
    },
    {
        path: ROUTE_ORIGINS_URL,
        element: <NirmalPanth />
    },
    {
        path: ROUTE_SANT_BABA_HARNAM_SINGH_JI_URL,
        element: <SantBabaHarnamSinghJi />
    },
    {
        path: ROUTE_SANT_BABA_BASANT_SINGH_JI_URL,
        element: <SantBabaBasantSinghJi />
    },
    {
        path: ROUTE_SANT_BABA_GIAN_SINGH_JI_URL,
        element: <SantBabaGianSinghJi />
    },
    {
        path: ROUTE_SANT_BABA_HARBHAJAN_SINGH_JI_URL,
        element: <SantBabaHarbhajanSinghJi />
    },
    {
        path: ROUTE_SANT_BABA_JEET_SINGH_JI_URL,
        element: <SantBabaJeetSinghJi />
    },
    {
        path: ROUTE_BRADFORD_URL,
        element: <Bradford />
    },
    {
        path: ROUTE_HIGH_WYCOMBE_URL,
        element: <HighWycombe />
    },
    {
        path: ROUTE_OLDBURY_URL,
        element: <Oldbury />
    },
];

export default AppRoutes;
