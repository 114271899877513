import React from "react";
import { CommonView } from "../common-view/CommonView";

export const SantBabaGianSinghJi = () => {

    return (
        <CommonView cssId={'banner-image-sant-gian-singh'} title={'Sant Baba Gian Singh Ji'}>
            <p>
                Sant Baba Gian Singh Ji was born on 25 November 1912, in village Bambeli, Tehsil
                Mahilpur, District Hoshiarpur. The parents of Sant Baba Gian Singh Ji were dedicated followers of Sikhism.
            </p>
            <p>
                At sixteen years old they renounced their home, parents and family life and came to Nirmal Kutiya Johalan.
                While at the Kutiya they performed all kinds of sewa. Attending to the cattle, horses and cleaning the
                stables were all a part of their daily duties alongside cooking langar for the sangat and Sant Baba Basant
                Singh Ji. Sant Baba Gian Singh Ji also spent years studying and reciting Gurbani. Through years of selfless
                service and recitation of Gurbani they reached the highest levels of spirituality. Sant Baba Basant Singh Ji
                Maharaj recognised this and passed all their austerities onto Sant Baba Gian Singh Ji and stated that he
                would become a very popular and regal Saint.
            </p>
            <p>
                After Sant Baba Gian Singh Ji’s Dastar Bandhi (Coronation as Head Saint of Nirmal Kutiya Johalan) they
                travelled across India and abroad to the UK, USA, Canada and Europe to spread the message of the Almighty.
                They preached the teachings enshrined in Sri Guru Granth Sahib Ji and they encouraged the sangat to become
                baptised with Sri Guru Gobind Singh Ji’s Amrit - the ambrosial nectar of the double-edged sword. Sant Baba
                Gian Singh Ji spent thirty years in guiding the sangat to follow the righteous path and improve their
                spiritual life.
            </p>
            <p>
                Sant Baba Gian Singh Ji was involved in many social activities and they were instrumental in constructing
                Gurdwaras around the world. In order for the local sangat to become disciplined in the Sikh way of life.
                Sant Baba Gian Singh Ji established Gurdwaras under the name of ‘Gurdwara Amrit Parchar Dharmik Diwan’ in
                England and India.
            </p>
            <p>
                The management, upkeep and service of these Gurdwaras are performed as per the orders and guidance of the
                Holy Saints of Nirmal Kutiya Johalan. A management committee comprising of five members from the local
                sangat are appointed under the current Head of Nirmal Kutiya Johalan to look after the day to day
                administration of these Gurdwaras.
            </p>
            <p>
                Braham Giani Sri Maan 108 Sant Baba Gian Singh Ji’s soul left their human body to remain forever at the feet
                of the Almighty on 6 November 2001. As per tradition they were immersed in the waters of the river Sutlej at
                Gurdwara Bhabhor Sahib, in District Ropar.
            </p>
        </CommonView>
    )

}
