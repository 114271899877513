import React from "react";
import diamond_divider_white from "../../../global/images/diamond-divider_whitebg.gif";

const CommonViewBannerImage = ({ cssId }) => {
    return (
        <section className="bannerr"
            id={cssId}>
        </section>
    )
}

const CommonViewBanner = ({ title }) => {
    return (
        <section className="content-heading">
            <div className="content-heading-border-diamond"></div>
            <h1>{title}</h1>
            <div className="content-heading-border-diamond"></div>
        </section >
    )
}

const CommonViewContentContainer = ({ children }) => {
    return (
        <section id="main-content" >
            <div className="blue-border"></div>
            <section id="main-content-container">
                {children}
            </section>
            <div className="blue-border"></div>
        </section>
    )
}

const CommonViewFooter = () => {
    return (
        <div id="content-bottom-tiles">
            <div className="blue-border"></div>

            <div className="blue-border"></div>
        </div>
    )
}

export const CommonView = ({ cssId, title, children }) => {
    return (
        <div>
            <CommonViewBannerImage cssId={cssId} />
            <CommonViewBanner title={title} />
            <CommonViewContentContainer>
                <div className="blue-border"></div>
                <section id="main-content-container">
                    <img src={diamond_divider_white} alt="diamond page divider" />
                    {children}
                </section>
                <img src={diamond_divider_white} alt="diamond page divider" />
            </CommonViewContentContainer>
            <CommonViewFooter />
        </div>
    )
}