import React from "react";
import { CommonView } from "../common-view/CommonView";

export const SantBabaHarnamSinghJi = () => {

    return (
        <CommonView cssId={'banner-image-sant-harnam-singh'} title={'Sant Baba Harnam Singh Ji'}>
            <p>
                Sant Baba Harnam Singh Ji famously placed the foundation stone for Takht Sri Kesgarh Sahib in Anandpur.
            </p>
            <p>
                As well as establishing another branch of Sants, Baba Karam Singh Ji Maharaaj continued the Hoti Mardan
                lineage with the appointment of Maharaaj Baba Lal Singh Ji. To this day, Hoti Mardan has very strong links
                with Johalan.
            </p>
            <p>
                Traditionally, when a successor to a Sant is appointed, a "Dastar Bandee" (Turban-tying) ceremony takes
                place to symbolise the passing of temporal authority to the new head Sant. As the Johalan branch was
                established from the Hoti Mardan branch, it is still tradition for Sant Baba Roshan Singh Ji Maskin, the
                current head Sant at Hoti Mardan, to carry out the Dastar Bandee.
            </p>
        </CommonView>
    )

}
